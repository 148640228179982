import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.js";
import { Email } from '../components/Email';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Webovú stránku dorucim.online (ďalej len „webová stránka“) prevádzkujú fyzické osoby: Tomáš Igrini, Ing. Roman Veselý, PhDr. Jarmila Lichvárová (ďalej len „prevádzkovateľ“).`}</p>
    <p>{`Kontakt: `}<Email mdxType="Email" /></p>
    <p><strong parentName="p">{`Používaním tejto webovej stránky súhlasíte s nižšie uvedenými podmienkami používania.`}</strong></p>
    <h2>{`I. Prevádzkovateľ nenesie zodpovednosť za:`}</h2>
    <ol>
      <li parentName="ol">{`správnosť a aktuálnosť obsahu publikovaného klientom prostredníctvom webového rozhrania.`}</li>
      <li parentName="ol">{`za škody spôsobené priamo či nepriamo v nadväznosti na informácie získane z webovej stránky dorucim.online`}</li>
      <li parentName="ol">{`škody spôsobené vírusmi a aplikáciami, ktoré sa mohli nainštalovať pri návšteve iných stránok prostredníctvom hypertextových odkazov, alebo pri prenose dát medzi stránkami a zariadením používateľa`}</li>
      <li parentName="ol">{`za bezporuchovosť webovej stránky.`}</li>
      <li parentName="ol">{`za inzerciu uverejnenú v rozpore s platnými právnymi predpismi a aktuálne prebiehajúcimi epidemiologickými opatreniami.`}</li>
    </ol>
    <h2>{`II. Všeobecné podmienky používania tejto webovej stránky:`}</h2>
    <ol>
      <li parentName="ol">{`Používateľ stránky (rozumej „predajca“) nesmie uverejniť na stránku osobné informácie o 3 osobách, na ktorých zverejnenie nemá ich predchádzajúci súhlas.`}</li>
      <li parentName="ol">{`Registráciou na stránke, predajca vyjadruje súhlas s Podmienkami používania stránky.`}</li>
      <li parentName="ol">{`Používateľ, ktorý má menej ako 18 rokov, čestne vyhlasuje, že súhlas s pravidlami tejto internetovej stránky vyslovil jeho zákonný zástupca. Stránku môže používať len pod jeho dohľadom.`}</li>
      <li parentName="ol">{`Prevádzkovateľ webovej stránky si vyhradzuje právo vymazať obsah, ktorý zahŕňa vulgarizmy, rasistické, zastrašujúce, osočujúce, neslušné, xenofóbne alebo iné vyjadrenia, ktoré sú v rozpore s dobrými mravmi, pravidlami slušnosti alebo v rozpore s platnými právnymi predpismi a to kedykoľvek bez uvedenia dôvodu.`}</li>
      <li parentName="ol">{`Inzerovať je možné iba služby a tovar, ktoré sú v súlade s právnym poriadkom SR a nepodliehajú špecifickej legislatíve.`}</li>
      <li parentName="ol">{`Prevádzkovateľ v prípade porušenia vyššie uvedených pravidiel môže: upraviť inzerát alebo vymazať inzerát, vymazať používateľa.`}</li>
      <li parentName="ol">{`Prevádzkovateľ nemá povinnosť zálohovať, alebo uchovávať obsah stránky. Túto povinnosť nemá ani po odstránení obsahu zo stránky, pokiaľ to neupravujú podmienky stránky, alebo pokiaľ si to nevyžadujú právne predpisy.`}</li>
      <li parentName="ol">{`Prevádzkovateľ si vyhradzuje právo zmeniť dizajn webovej stránky bez predchádzajúceho súhlasu používateľa a pridať nové funkcionality, pokiaľ to zvýši kvalitu stránky a jej využiteľnosť a nevyžaduje to od používateľa poskytnutie osobných údajov, na ktoré používateľ nedal súhlas. Používateľ nemá povinnosť tieto nové funkcionality využívať, pokiaľ to nie je v jeho vlastnom záujme.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      