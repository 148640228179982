import React from 'react';

import { Meta } from '../components/Meta';
import { Main } from '../components/Main';

export default ({
  children,
  pageContext: {
    frontmatter: { slug, title },
  },
}) => (
  <Main>
    <Meta title={title} pathname={slug} />
    <h1>{title}</h1>

    {children}
  </Main>
);
